<template>
  <div class="modal fade" id="informationFinalModal" tabindex="-1" role="dialog" aria-labelledby="informationFinalModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="informationFinalModalLabel"><i class="bi bi-app-indicator"></i> Graphisoft C++ ITech Challenge 2022 döntő</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-8">
              <h1>Kedves {{ userData.firstName }},</h1>
              <p>Köszöntünk a GRAPHISOFT C++ ITech Challenge 2022 döntőjében.</p>


              <h2><i class="bi bi-1-square"></i> Első lépés</h2>
              <p>A felületen elérhető a GraphiLand++ játékunk leírása, szabályokkal, összefüggésekkel, időzítéssel, pontozással, és minden fontos információval.</p>
              <p>A már meglévő elődöntős kódotokat kell folytatni.</p>
              <h2><i class="bi bi-terminal"></i> Futtatások - hálózat</h2>
              <p>Az elődöntőn megismert módszerrel otthonról tudtok játékokat futtatni.</p>
              <p><kbd>&lt;exe neve&gt; {{userData.gitUser}} {{userData.gameToken}} 20.23.247.111 63155</kbd> - válaszidő 2 mp</p>
              <h2><i class="bi bi-terminal"></i> Futtatások - virtuális gép</h2>
              <p>Minden csapat kap egy ugyanolyan paraméterekkel rendelkező virtuális gépet, amelyek közös hálózaton vannak a döntős szerverrel.</p>
              <p>Ezekre a gépekre SSH segítségével tudtok belépni, és tudtok fordítani és futtatni.</p>
              <p><strong>A virtális gép adatai:</strong><br>
                IP: {{finalData.vmIp}}<br>
                Felhasználónév: {{userData.gitUser}}<br>
                Jelszó: {{finalData.vmPassword}}
              </p>
              <p><kbd>&lt;exe neve&gt; {{userData.gitUser}} {{userData.gameToken}}  10.210.44.5 63155</kbd> - válaszidő 2 mp</p>
              <p><kbd>&lt;exe neve&gt; {{userData.gitUser}} {{userData.gameToken}}  10.210.44.5 65000</kbd> - válaszidő 250 milliszekundum</p>
              <p>Az első és második futtatáskor ezeken a gépeken mi fogjuk a határidőig felcommitolt utolsó kódotokat fordítani és futtatni.</p>
              <h2><i class="bi bi-sign-stop"></i> Tilos</h2>
              <p>A döntő során a kódotoknak tilos:</p>
              <ul>
                <li>fájlműveleteket végrehajtani</li>
                <li>távoli elérést létesíteni</li>
                <li>futtatókörnyezetbe belenyúlni, annak működését felülírni</li>
                <li>futtatás közben felhasználói beavatkozásra reagálnia a kódotoknak</li>
              </ul>
              <p></p>
              <p>Jó programozást kívánunk!<br>
                Graphisoft C++ ITech Challenge csapata</p>
            </div>
            <div class="col-lg-4">
              <div class="card text-bg-secondary mb-3">
                <div class="card-header"><i class="bi bi-cloud-fog2"></i> Fordító</div>
                <div class="card-body">
                  <p>g++ 9.4.0</p>
                  <p>boost 1.70 header only library-k használhatóak.</p>
                  <p>-std=c++17 -Wall -O2 -static -pthread</p>
                  <p class="small">Ubuntu szerveren fordítunk, a számotokra és elérhető virtuális gépen.</p>
                </div>
              </div>
              <div class="card text-bg-secondary mb-3">
                <div class="card-header"><i class="bi bi-life-preserver"></i> Support</div>
                <div class="card-body">
                  <p>Ha kérdésed van, vagy szokatlan, nem megfelelő működést tapasztalsz, bátran írd meg nekünk az <a href="mailto:info@itechchallenge.hu">info@itechchallenge.hu</a> e-mail címre.</p>
                  <p>Vagy használhatod az élő Teams Live supportot.</p>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal"><i class="bi bi-caret-right-fill"></i> Tovább</button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { Modal } from 'bootstrap'

export default {
  name: 'FinalInformation',
  props: ['initData'],
  data() {
    let userData = this.initData.user
    let finalData = this.initData.data.final

    return {
      userData,
      finalData
    }
  },
  methods:{
    openModal(){
      this.modalInstance = new Modal(document.getElementById('informationModal'))
      this.modalInstance.show()
    },
  }
}
</script>


<style scoped>
h1{
  font-size: 2rem;
  line-height: 2rem;
}
</style>